<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.smAndDown" :fixed="$vuetify.breakpoint.smAndUp"
               color="primary" app dark>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="navToggle"></v-app-bar-nav-icon>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" @click.stop="navMiniToggle">
            <v-icon v-if="!mini">mdi-menu-open</v-icon>
            <v-icon v-if="mini" class="mdi-flip-h">mdi-menu-open</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" @click.stop="toggleFullscreen">
            <v-icon v-if="!isFullscreen">mdi-fullscreen</v-icon>
            <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
        </v-app-bar-nav-icon>
        <v-spacer/>
        <v-menu v-model="menu" nudge-bottom="30" min-width="200" transition="slide-y-transition" offset-y light>
            <template v-slot:activator="{ on }">
                <v-icon slot="activator" v-on="on"></v-icon>
            </template>
            <v-card>
                <v-list color="grey lighten-4" light dense>
                    <v-list-item v-for="item in items" :key="item.title" v-show="item.visible"
                                 @click.native="item.action ? item.action() : false" href="javascript:void(0)"
                                 :to="item.to" ripple :exact="item.exact !== undefined ? item.exact : true">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
        <v-avatar color="white" @click.stop="menu = !menu" size="44">
            <v-img :src="userAvatar" />
        </v-avatar>
        <v-icon @click.stop="menu = !menu">{{ menu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
        <v-card v-if="$vuetify.breakpoint.smAndUp" class="mx-auto" color="transparent" max-width="150" flat outlined>
            <v-list-item dense>
                <v-list-item-content>
                    <v-list-item-title>{{ userName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('user_position') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <v-divider vertical dark class="pr-2"></v-divider>
        <Languages></Languages>
    </v-app-bar>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import Languages from "./Languages"

export default {
    name: "AppTopMenu",
    components: {
        Languages,
    },
    //inject: ["forceRerender"],
    props: {
        mini: Boolean,
        drawer: Boolean,
    },
    data() {
        return {
            isFullscreen: false,
            menu: false,
            menuBadge: false,
            closeOnClickMenu: true,
            dialogNotify: false,
            dialogNotifyData: [],
            items: [],
            messages: 0,
            notifyItems: [],
            notifications: [],
            transparent: 'rgba(255, 255, 255, 0)',
            settingsScrollbar: {
                suppressScrollX: true,
            },
            notifyType: 'unread',
            notifyReadCount: 0,
            notifyUnreadCount: 0
        }
    },
    computed: {
        ...mapGetters(['lang', 'showNotifyMessages', 'userName', 'userAvatar']),
    },
    watch: {
        pushMessages(val) {
            if (val) {
                this.$toastr.success(`${val.title} ${val.message}`)
            }
        },
    },
    mounted() {
        this.menuNavigation()
        //this.getNotifications()
    },
    methods: {
        ...mapActions(['setSettings', 'setLanguage', 'setUserName', 'setUserPosition', 'setUserAvatar']),
        dialogNotifyHidden() {
            this.dialogNotify = false
            this.dialogNotifyData = []
            this.closeOnClickMenu = true
        },
        navToggle() {
            this.$emit("nav-toggle")
        },
        navMiniToggle() {
            this.$emit("nav-mini-toggle")
        },
        showNotify(notify) {
            this.dialogNotifyData = notify
            this.closeOnClickMenu = true
            this.dialogNotify = true
        },
        notifyShow(type) {
            if (this.notifications?.length > 0) {
                this.$nextTick(() => {
                    this.notifyType = type
                    if (type === 'read') {
                        this.notifyItems = this.notifications.filter(notify => notify.read_at !== null).slice()
                    } else {
                        this.notifyItems = this.notifications.filter(notify => notify.read_at === null).slice()
                    }
                })
            }
        },
        async getNotifications(action) {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            params.action = action ? action : 'all'
            await this.$http
                .get('admin/notification', {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.notifications = res.data

                    if (this.notifications?.length > 0) {
                        this.notifyReadCount = this.notifications.reduce((read, x) => (x.read_at !== null ? (read + 1) : read), 0)
                        this.notifyUnreadCount = this.notifications.reduce((unread, x) => (x.read_at === null ? (unread + 1) : unread), 0)

                        this.notifyShow(this.notifyType)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_notifications'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async notifyMark(type, notify) {
            if (type) {
                var _this = this
                this.loading = true
                let formData = new FormData()
                formData.append('action', type)
                if (notify && notify.id) {
                    formData.append('notify', notify.id)
                }
                await this.$http
                    .put(`admin/notification/${type}`, formData)
                    .then(res => {
                        if (notify) {
                            const index = this.notifications.indexOf(notify)
                            if (type === 'read') {
                                this.notifications[index].read_at = this.$moment.now()
                            } else if (type === 'unread') {
                                this.notifications[index].read_at = null
                            }
                        } else {
                            if (type === 'readAll') {
                                this.notifications.forEach(function (item, i, arr) {
                                    item.read_at = _this.$moment.now()
                                })
                                this.notifyType = 'read'
                            } else if (type === 'unreadAll') {
                                this.notifications.forEach(function (item, i, arr) {
                                    item.read_at = null
                                })
                                this.notifyType = 'unread'
                            }
                        }

                        this.notifyReadCount = this.notifications.reduce((read, x) => (x.read_at !== null ? (read + 1) : read), 0)
                        this.notifyUnreadCount = this.notifications.reduce((unread, x) => (x.read_at === null ? (unread + 1) : unread), 0)

                        this.notifyShow(this.notifyType)

                        //this.getNotifications()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('notify_has_not_been_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async notifyDelete(type, notify) {
            if (type) {
                this.loading = true
                let params = {}
                params.action = type
                if (notify && notify.id) {
                    params.notify = notify.id
                }
                await this.$http
                    .delete(`admin/notification/${type}`, {
                        params: params,
                    })
                    .then(res => {
                        if (notify) {
                            const index = this.notifications.indexOf(notify)
                            if (type === 'delete') {
                                this.notifications.splice(index, 1)
                            }
                        } else {
                            if (type === 'deleteAll') {
                                this.notifications = []
                            }
                        }

                        this.notifyShow(this.notifyType)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('notify_has_not_been_deleted'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
            this.dialogNotify = false
            this.dialogNotifyData = []
            this.closeOnClickMenu = true
        },
        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },
        menuNavigation() {
            this.items = [
                {
                    title: this.$t('profile'),
                    icon: "mdi-account",
                    to: {
                        name: 'profile'
                    },
                    visible: this.$auth.check(),
                    action: this.forceRerender,
                    exact: false
                },
                {
                    title: this.$t('menu_tracking'),
                    icon: "mdi-arrow-decision",
                    to: {
                        name: 'tracking_container'
                    },
                    visible: this.$auth.check(),
                    action: this.forceRerender,
                    exact: false
                },
                {
                    title: this.$t('logout'),
                    icon: "mdi-logout-variant",
                    visible: this.$auth.check(),
                    action: this.logout
                }
            ]
        },
        toggleFullscreen: function (event) {
            var elem = document.documentElement
            if (
                document.fullscreenEnabled ||
                document.webkitFullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.msFullscreenEnabled
            ) {
                if (!this.isFullscreen) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.webkitRequestFullscreen) {
                        elem.webkitRequestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.mozRequestFullScreen) {
                        elem.mozRequestFullScreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.msRequestFullscreen) {
                        elem.msRequestFullscreen()
                        this.isFullscreen = true
                        return
                    }
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen()
                        this.isFullscreen = false
                        return
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen()
                        this.isFullscreen = false
                        return
                    }
                }
            }
        },
    }
}
</script>
