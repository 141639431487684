import Vue from "vue"

window.toastr = require("toastr")
import VueToastr2 from "vue-toastr-2"
//import YmapPlugin from "vue-yandex-maps"
import VueAuth from "@websanova/vue-auth"
import { Tree } from "element-ui"
import moment from "moment-timezone"
import VueObserveVisibility from "vue-observe-visibility"
import VueResource from "vue-resource"
import App from "./App.vue"
import auth from "./plugins/auth"
import i18n from "./plugins/i18n"
import router from "./plugins/router"
import store from "./plugins/store"
import vuetify from "./plugins/vuetify"
import "./registerServiceWorker"

require("./plugins/prototype")
require("./plugins/vee-validate")

import "@mdi/font/css/materialdesignicons.css"
import "cropperjs/dist/cropper.css"
import "element-ui/lib/theme-chalk/index.css"
import "roboto-fontface/css/roboto/roboto-fontface.css"
import "vue-toastr-2/dist/vue-toastr-2.min.css"
import "./styles/custom.css"

require("./plugins/firebase")

Vue.use(VueObserveVisibility);
Vue.config.productionTip = false

Vue.component(Tree.name, Tree)

moment.locale(i18n.locale)
//moment.defaultFormat = "YYYY-MM-DD HH:mm:ss"
Vue.prototype.$moment = moment

Vue.use(VueToastr2, {
    debug: false,
    closeButton: true,
    extendedTimeOut: 1000,
    timeOut: 3000,
    progressBar: false,
    preventDuplicates: true,
    onclick: null,
    positionClass: "toast-bottom-right",
})

Vue.prototype.$yandex = {
    apiKey: process.env.VUE_APP_YANDEX_MAP_KEY,
    lang: "ru_RU",
    coordorder: "latlong",
    version: "2.1"
}

Vue.prototype.$google = {
    maps_key: process.env.VUE_APP_GOOGLE_MAP_KEY
}

Vue.prototype.$pusherServer = process.env.VUE_APP_REVERB_SERVER

Vue.use(VueResource)

Vue.http.options.credentials = true;
Vue.http.options.emulateHTTP = true
//Vue.http.options.emulateJSON = true
//Vue.http.options.crossOrigin = true
Vue.http.headers.common['Accept'] = "application/json"
Vue.http.headers.common['Content-Type'] = "application/json"
Vue.http.options.root = process.env.VUE_APP_API_URL + "/api/v1"

Vue.http.interceptors.push((request, next) => {
    if (request.emulateHTTP && /^(PUT|PATCH|DELETE)$/i.test(request.method)) {
        request.headers.set('X-HTTP-Method-Override', request.method);
        request.method = 'POST';
    }
    request.headers.set('Accept-Language', i18n.locale);
    next(function (response) {
        if (response.status === 401) {
            Vue.prototype.$auth.logout()
        }
        if (response.status === 503) {
            if (Vue.prototype.$auth.check()) {
               Vue.prototype.$auth.logout()
           }
            Vue.router.push('/maintenance')
        }
    })
})

Vue.router = router
Vue.use(VueAuth, auth)
new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app")
