import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/messaging'

firebase.initializeApp({
    apiKey: "AIzaSyA6P7fuVCVe_sblajlwV60hjl6CKitHH6o",
    authDomain: "atasugroup-c66b8.firebaseapp.com",
    projectId: "atasugroup-c66b8",
    storageBucket: "atasugroup-c66b8.appspot.com",
    messagingSenderId: "481226396270",
    appId: "1:481226396270:web:0fe29f346811cf080f0c7f",
    measurementId: "G-G91H1HP3T8"
})

if (firebase.messaging.isSupported()) {

    window.messaging = firebase.messaging()

    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            window.messaging.useServiceWorker(registration)
        }).catch(err => {
        console.log(err)
    })

} else {

    console.log("Browser doesn't support FCM")
}
