<template>
    <div v-if="loaded" class="fill-height" :key="componentKey">
        <app-top-menu style="background:#6680b0 !important;" :mini="mini" :drawer="drawer"
                      @nav-mini-toggle="navMiniToggle"
                      @nav-toggle="navToggle"></app-top-menu>
        <app-sidebar style="background:#45649f !important;" :mini="mini" :drawer="drawer"
                     @nav-mini-toggle="navMiniToggle"
                     @nav-toggle="navToggle"></app-sidebar>
        <v-main>
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </v-main>
        <app-footer></app-footer>
    </div>
</template>
<script>
import Vue from 'vue'
import mixin from '../../plugins/mixin'

Vue.mixin(mixin)

import Pusher from 'pusher-js'
import Echo from "laravel-echo"

import {mapActions, mapGetters} from "vuex"
import AppFooter from "../../components/AppFooter"
import AppSidebar from "../../components/AppSidebar"
import AppTopMenu from "../../components/AppTopMenu"

export default {
    name: "MainLayout",
    components: {
        AppSidebar,
        AppTopMenu,
        AppFooter
    },
    props: {
        source: String,
        push: Object
    },
    provide: function () {
        return {
            forceRerender: this.forceRerender,
        }
    },
    data() {
        return {
            loaded: false,
            mini: false,
            drawer: false,
            componentKey: 0,
        }
    },
    computed: {
        ...mapGetters(['lang', 'defaultAvatar']),
    },
    watch: {
        mini(val) {
            if (this.$vuetify.breakpoint.smAndDown || !this.drawer) {
                this.mini = false
            } else {
                this.mini = (this.$vuetify.breakpoint.smAndUp && val)
            }
        },
    },
    async mounted() {
        await this.$auth
            .load()
            .then(async () => {
                if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                    this.setSettings(this.$auth.user().settings)
                    this.setUserName(this.$auth.user().name)
                    this.setUserPosition(this.$auth.user().position)
                    this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                    await this.listenChannelWebsocket()
                    await this.listenChannelFCM()
                }
                this.loaded = true
            })

        this.setLang()

        this.drawer = this.$vuetify.breakpoint.mdAndUp
        this.mini = this.$vuetify.breakpoint.mdOnly
    },
    methods: {
        ...mapActions(['setSettings', 'setLanguage', 'setUserName', 'setUserPosition', 'setUserAvatar']),
        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        setLang() {
            let language = this.$auth.user().language ?? this.getCookie('language')
            if (language == null && this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language

            } else {
                document.documentElement.lang = language
            }
            this.$vuetify.lang.current = document.documentElement.lang
            this.$i18n.locale = document.documentElement.lang
            this.setLanguage(document.documentElement.lang)
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            this.forceRerender()
        },
        forceRerender: function () {
            this.componentKey += 1
        },
        navToggle() {
            this.drawer = !this.drawer
            if (!this.drawer) {
                this.navMiniToggle()
            }
        },
        navMiniToggle() {
            this.mini = !this.mini
        },
        async listenChannelFCM() {
            if (window.messaging) {
                await window.messaging.requestPermission()
                    .then(async () => {
                        console.log('Notification permission granted.')
                        await window.messaging.getToken().then(async (token) => {
                            //console.log('New token created: ', token)
                            await this.saveNotificationToken(token)
                            //await this.setTokenToLocalStorage(false)
                        })
                    })
                    .catch((err) => {
                        console.log('Unable to get permission to notify.', err)
                        //this.setTokenToLocalStorage(false);
                    })
                window.messaging.onTokenRefresh(function () {
                    window.messaging.getToken()
                        .then(function (newToken) {
                            //console.log('Token refreshed: ', newToken)
                            this.saveNotificationToken(newToken)
                            //this.setTokenToLocalStorage(newToken);
                        })
                        .catch(function (err) {
                            console.log('Unable to retrieve refreshed token ', err)
                            //this.setTokenToLocalStorage(false);
                        })
                })
                window.messaging.onMessage(function (payload) {
                    Notification.requestPermission(function (result) {
                        if (result === "granted") {
                            navigator.serviceWorker.ready
                                .then(function (registration) {
                                    const data = {...payload.notification, ...payload.data};
                                    const notificationTitle = data.title;
                                    let notificationOptions = {
                                        body: data.body,
                                        requireInteraction: true,
                                        data
                                    }
                                    if (data.icon) {
                                        notificationOptions.icon = data.icon
                                    }
                                    if (data.image) {
                                        notificationOptions.image = data.image
                                    }
                                    if (data.click_action) {
                                        notificationOptions.click_action = data.click_action
                                    }
                                    return registration.showNotification(
                                        notificationTitle,
                                        notificationOptions
                                    )
                                })
                                .catch(function (error) {
                                    console.log("ServiceWorker registration failed", error);
                                })
                        }
                    })
                })
            }
        },
        async saveNotificationToken(token) {
            const params = {
                token: token,
                type: 'browser'
            }
            this.$http
                .post("client/device", params)
                .then((res) => {
                    console.log('Successfully saved notification token!')
                })
                .catch((err) => {
                    console.log('Error: could not save notification token')
                })
        },
        async listenChannelWebsocket() {
            if (window.Echo) delete window.Echo
            if (window.Pusher) delete window.Pusher
            window.Pusher = Pusher
            window.Echo = new Echo({
                broadcaster: 'reverb',
                key: process.env.VUE_APP_REVERB_APP_KEY,
                wsHost: process.env.VUE_APP_REVERB_HOST,
                wsPort: process.env.VUE_APP_REVERB_PORT ?? 80,
                wssPort: process.env.VUE_APP_REVERB_PORT ?? 443,
                forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
                enabledTransports: ["wss", "ws"],
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            this.$http.post(`${this.$pusherServer}/api/broadcasting/auth`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(res => {
                                    callback(false, res.data)
                                })
                                .catch(err => {
                                    callback(true, err)
                                })
                        }
                    }
                }
            })
            window.Echo
                .private(`${this.$auth.user().uuid}`)
                .listen('.admin', (e) => {
                    console.log('Listen Private Channel', e)
                    if (e.type === 'event') {
                        if (e.event === 'notify') {
                            console.log('Admin notify data', e.data)
                            if (e.data?.type === 'message') {
                                if (e.data.importance === 'success') {
                                    this.$toastr.success(`${e.data.title} ${e.data.message}`)
                                } else if (e.data.importance === 'error') {
                                    this.$toastr.error(`${e.data.title} ${e.data.message}`)
                                } else if (e.data.importance === 'warning') {
                                    this.$toastr.warning(`${e.data.title} ${e.data.message}`)
                                } else {
                                    this.$toastr.info(`${e.data.title} ${e.data.message}`)
                                }
                            }
                        }
                        if (e.event === 'notifyRefresh') {
                            console.log('Admin notifyRefresh data', e.data)
                            // notifyRefresh()
                        }
                    }
                })
                .notification((e) => {
                    console.log('Listen Notification', e)
                    if (e.type === 'notify') {
                        if (e.importance === 'success') {
                            this.$toastr.success(`${e.title} ${e.message}`)
                        } else if (e.importance === 'error') {
                            this.$toastr.error(`${e.title} ${e.message}`)
                        } else if (e.importance === 'warning') {
                            this.$toastr.warning(`${e.title} ${e.message}`)
                        } else {
                            this.$toastr.info(`${e.title} ${e.message}`)
                        }
                        // notifyRefresh()
                    }
                })
        },
    }
}
</script>
